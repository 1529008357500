<template>
  <div>
    <div class="container">
      <span
        :class="{
          'block text-sm text-left font-medium text-slate-700}': classInvalid,
          'block text-sm text-left font-medium text-slate-700 text-red-500':
            !classInvalid,
        }"
        >{{ label }}</span
      >
      <input
        v-if="type != 'time'"
        :id="id"
        :type="type"
        :value="modelValue"
        @input="this.$emit('update:modelValue', $event.target.value)"
        autocomplete="off"
        :readonly="readonly"
        :class="{
          'border-2 rounded px-2 h-8 w-full hover:border-blue-400':
            classInvalid,
          'has-error border-2 rounded px-2 h-8 w-full border-red-500 focus:outline-none focus:ring focus:ring-red-300':
            !classInvalid,
        }"
      />
      <vue-timepicker
        v-else
        :id="id"
        :value="modelValue"
        :v-model="modelValue"
        :minute-range="[0]"
        @change="inputTime"
        format="HH:mm"
        :input-class="{
          'border-2 rounded border-gray-200 px-2 h-8 w-full hover:border-blue-400':
            classInvalid,
          'has-error border-2 rounded px-2 h-8 w-full border-red-500 focus:outline-none focus:ring focus:ring-red-300':
            !classInvalid,
        }"
      >
        <template v-slot:icon>
          <img :src="icon" />
        </template>
      </vue-timepicker>
      <!-- <input
        v-else
        :id="id"
        :type="type"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        autocomplete="false"
        step="60"
        :readonly="readonly"
        :class="{
          'border-2 rounded px-2 h-8 w-full hover:border-blue-400':
            classInvalid,
          'has-error border-2 rounded px-2 h-8 w-full border-red-500 focus:outline-none focus:ring focus:ring-red-300':
            !classInvalid,
        }"
      /> -->
      <span
        v-if="!classInvalid"
        class="block text-sm text-red-500 pt-2 text-left font-medium text-slate-700"
        >{{ errorMessage }}</span
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
      note: "Id for the input",
    },
    type: {
      type: String,
      required: false,
      default: "text",
      note: "Type for the password",
    },
    label: {
      type: String,
      required: false,
      note: "Label for the input",
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
      note: "Label for the input",
    },
    modelValue: {
      type: [String, Number, Date],
      default: "",
      note: "Value for the input",
    },
    classInvalid: {
      type: Boolean,
      required: false,
      default: true,
      note: "Label for the input",
    },
    errorMessage: {
      type: String,
      required: false,
      default: "ต้องไม่เป็นค่าว่าง",
      note: "Error message input",
    },
  },
  data() {
    return {
      icon: require("../../assets/images/clock.png"),
    };
  },
  watch: {},
  computed: {
    inputValue: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    inputTime(eventData) {
      this.$emit("update:modelValue", eventData.displayTime);
    },
    // inpValue(val) {
    //   if (this.id === "phone") this.validatePhone(val);
    //   // else this.$emit("update:modelValue", val);
    //   // this.$emit("update:modelValue", $event.target.value);
    // },
    // validatePhone(phone) {
    //   if (phone.length > 10) {
    //     this.$emit("update:modelValue", phone.substring(0, 10));
    //   }
    // },
  },
  // mounted() {
  //   const inpStart = document.getElementById("startTime");
  //   inpStart.className =
  //     "border-2 rounded px-2 h-8 w-full hover:border-blue-400";

  //   const inpEnd = document.getElementById("endTime");
  //   inpEnd.className = "border-2 rounded px-2 h-8 w-full hover:border-blue-400";
  //   console.log();
  // },
};
</script>

<style>
.vue__time-picker {
  display: unset;
}

.vue__time-picker input.vue__time-picker-input {
  width: 100% !important;
  /* border: 2px solid #e5e7eb !important; */
  border-radius: 0.25rem;
  padding: 0 0 0 2rem !important;
}
</style>
